.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 90%;
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  transition: opacity 0.2s ease-in;
}

.modal-container.closed {
  opacity: 0;
  pointer-events: none;
}

.close-modal-button {
  font-size: 1rem;
  position: absolute;
  top: -30px;
  right: 0;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.close-modal-button:hover {
  color: white;
  background-color: red;
  font-weight: 700;
}

.unsupported-text {
  text-align: center;
  color: white;
  width: 100%;
  margin-top: 2rem;
}
