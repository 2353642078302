.scrollDownLink {
  position: relative;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  animation: fadeInDown 1.5s ease-in-out;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.scrollDownLink:hover {
  filter: drop-shadow(0px 0px 10px black);
}

.scrollDownContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: white;
  opacity: 0.8;
}

.mouseSVG {
  width: 50px;
}

.fa-chevron-down {
  font-size: 12px;
}

.scrollDownText {
  font-size: 12px;
  margin: 0;
}

.bounce {
  animation: bounceDown 1.5s infinite 2s;
}

@keyframes bounceDown {
  0%,
  100% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(4px);
  }
}
