.projects {
  background: linear-gradient(
    180deg,
    var(--color-primary-dark),
    var(--color-primary-light) 100%
  );
  margin: 0 auto;
  text-align: center;
  padding-bottom: 1rem;
}

.projects h1 {
  margin: 0 auto 4rem;
  padding-top: 4rem;
  width: max-content;
  color: var(--color-offWhite);
  font-size: 3rem;
  font-weight: 500;
  border-bottom: 2px solid;
}

.projects .githubLink {
  display: inline-block;
  padding: 0.7rem 1.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: none;
  border-radius: 15px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  box-shadow: rgb(38, 57, 77) 0px 7px 12px -5px;
}

.projects .githubLink:hover {
  background: linear-gradient(
    180deg,
    var(--color-primary) 0%,
    var(--color-primary-light) 100%
  );
  color: white;
  border: 2px solid white;
  box-shadow: 0 0 0 0;
}

/* from https://www.shapedivider.app/ */
.topAngle-svg {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.topAngle-svg svg {
  position: relative;
  display: block;
  width: calc(222% + 1.3px);
  height: 38px;
}

.topAngle-svg .shape-fill {
  fill: var(--color-primary-dark);
}

.bottomAngle-svg {
  bottom: 0;
  transform: rotate(180deg);
}

.bottomAngle-svg svg {
  position: relative;
  display: block;
  width: calc(222% + 1.3px);
  height: 38px;
}

.bottomAngle-svg .shape-fill {
  fill: var(--color-primary-light);
}

@media screen and (max-width: 767px) {
  .projects {
    padding: 0 0.3rem 3rem;
    width: 100%;
  }

  .projects h1 {
    margin-bottom: 2.5rem;
    font-size: 2rem;
  }

  .projects .githubLink {
    margin: 1.5rem 0 0;
    font-size: 0.65rem;
    padding: 1rem;
  }

  .topAngle-svg svg,
  .bottomAngle-svg svg {
    height: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 940px) {
  .projects h1 {
    width: 40%;
  }
}
