@import "./assets/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap");

:root {
  --color-primary: rgb(29, 107, 202);
  --color-primary-dark: rgb(14, 81, 163);
  --color-primary-light: rgb(44, 139, 255);
  --color-offWhite: rgb(230, 230, 230);
}

body {
  box-sizing: border-box;
  width: 100%;
  font-family: "Lexend", sans-serif !important;
  background-color: var(--color-offWhite);
  background-image: url("https://www.transparenttextures.com/patterns/arches.png");
  overflow-x: hidden;
}

html {
  width: 100%;
}

/* Animation From Animista */

.fade-in {
  -webkit-animation: fade-in 0.75s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.75s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.shake-bottom {
  -webkit-animation: shake-bottom 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
  animation: shake-bottom 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

/* Scroll Bar */

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #777;
}

::-webkit-scrollbar-thumb:active {
  background: #666;
}
