.aboutMeContainer {
  text-align: center;
  width: 100%;
  position: relative;
}

.backgroundLinear {
  background: linear-gradient(180deg, #01254c 0%, var(--color-primary) 100%);
}

h1.aboutMeHeader {
  margin: 0 auto 4rem;
  padding-top: 1rem;
}

.about-me {
  width: 80%;
  max-width: 1600px;
  overflow-x: hidden;
}

.about-me p {
  padding: 0 0.75rem;
  color: var(--color-offWhite);
  font-size: 0.85rem;
  letter-spacing: 0.8px;
  text-align: left;
}

p.bigger {
  font-size: 1rem;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

img.profile {
  width: 13rem;
  height: 13rem;
  transition: 0.3s ease-in-out;
  border-radius: 100%;
}

.textContainer {
  margin: 0 0 2rem 0;
}

.StatBoxesRow {
  overflow: hidden;
  padding-bottom: 2rem !important;
}

/* from https://www.shapedivider.app/ */
.wave {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(160% + 1.3px);
  height: 80px;
}

.wave .shape-fill {
  fill: #01254c;
}

.bottom-wave {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.bottom-wave svg {
  position: relative;
  display: block;
  width: calc(160% + 1.3px);
  height: 80px;
}

.bottom-wave .shape-fill {
  fill: var(--color-primary);
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  img.profile {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 940px) {
  h1.aboutMeHeader {
    width: 80%;
    margin-bottom: 2.5rem;
    padding-top: 2rem;
    font-size: 1.8rem;
  }

  img.profile {
    width: 13rem;
    height: 13rem;
    margin-bottom: 2rem;
  }

  .about-me {
    width: 90%;
  }

  .about-me p {
    font-size: 14px;
    text-align: center;
  }

  p.bigger {
    font-size: 16px;
  }

  .wave svg,
  .bottom-wave svg {
    height: 50px;
  }
}

@media screen and (min-width: 1600px) {
  .about-me {
    padding: 1rem 17rem;
  }
}
