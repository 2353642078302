.row {
  display: flex;
  justify-content: space-between;
}

.card {
  border-radius: 14px;
  transition: all 0.2s ease-in-out;
  margin: 1rem auto 3rem;
  box-shadow:
    2px 2px 10px rgba(0, 0, 0, 0.5),
    -2px -2px 10px rgba(0, 0, 0, 0.5);
  background-color: rgb(240, 240, 240);
  min-height: 41rem;
}

.card:hover {
  transform: scale(1.03);
  box-shadow:
    6px 6px 13px rgba(0, 0, 0, 0.5),
    -6px -6px 13px rgba(0, 0, 0, 0.5);
}

.card-title {
  border-bottom: 1.5px solid var(--color-primary-light);
  color: var(--color-primary-light);
  width: 100%;
  text-align: left;
  margin: 0 0 1rem;
  padding-bottom: 0.3rem;
  font-size: 1.35rem;
  font-weight: 700;
}

.card-img-top {
  border-radius: 14px 14px 0 0;
  min-height: 13rem;
  filter: blur(2px);
  transition: all 0.5s ease;
}
.card:hover .card-img-top,
.card:active .card-img-top {
  filter: blur(0);
}

.topCardBody {
  min-height: 11.5rem;
  padding-bottom: 0.5rem;
}
.card-1 .topCardBody,
.card-2 .topCardBody {
  min-height: 14rem;
}

.card-text {
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 16px;
  text-align: left;
}

.tool {
  background-color: var(--color-primary-light);
  color: white;
  display: inline-block;
  margin: 0.2rem;
  padding: 0.4rem;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  box-shadow: 1px 1px 5px #333;
  font-size: 13px;
}

.tool:hover {
  transform: scale(1.07);
  background-color: var(--color-primary);
}

.list-group-item {
  padding: 0.7rem 0.3rem;
  background-color: rgb(240, 240, 240);
}

.list-group + .card-body {
  padding: 0.8rem 0 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card button {
  border-radius: 10px;
  font-size: 1rem;
  padding: 0.5rem 0.7rem;
  text-transform: capitalize;
  background-color: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 10px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.card button:hover {
  border: 2px solid white;
  background-color: var(--color-primary);
  color: white;
}

.card button:active {
  background-color: var(--color-primary);
}

.card button:focus {
  outline: none;
}

.card .greyButton {
  border: 2px solid #777;
  color: #777;
}

.card .greyButton:hover,
.card .greyButton:active {
  background-color: #777;
}

.includesGetJokes {
  color: var(--color-primary-light);
}

.card .cardButtonsContainer {
  padding: 0.8rem 0;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
    min-height: 0;
  }

  .topCardBody {
    min-height: 0;
  }

  .card-1 .topCardBody,
  .card-2 .topCardBody {
    min-height: 0;
  }

  .card-text {
    font-size: 14px;
  }

  .tool {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 940px) {
  .card {
    margin: 1rem auto 1.5rem;
  }

  .card:hover {
    transform: none;
    box-shadow:
      6px 6px 13px #333,
      -6px -6px 13px #333;
  }
}
