.backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  overflow: hidden;
  transition: opacity 0.2s ease-in;
}

.backdrop.closed {
  opacity: 0;
  pointer-events: none;
}
