.skills {
  margin: 4rem auto;
  padding-bottom: 1rem;
  text-align: center;
  overflow: hidden;
}

.skillContainer {
  display: inline;
}

.skillsSet {
  margin-top: 3rem;
}

.skills h2 {
  color: var(--color-primary-light);
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 1.5rem auto 0;
  width: max-content;
}

.skills h2:hover + hr {
  width: 2%;
}

.skills hr {
  background-color: var(--color-primary-light);
  width: 3%;
  height: 1px;
  margin: 0.5rem auto 2rem;
  transition: 0.25s ease;
}

.skills .skill {
  display: inline-block;
  font-weight: bold;
  font-size: 0.64rem;
  letter-spacing: 0.5;
  margin: 0.2rem 0.7rem 1rem;
  transition: all 0.2s ease-in-out;
  background-color: rgba(220, 220, 220, 0.6);
  padding: 0.5rem;
  border-radius: 15px;
  width: 6rem;
}

.skills .skill:hover {
  transform: translateY(-10px);
  background-color: rgba(235, 235, 235, 0.7);
}

.skills p {
  margin-bottom: 0;
}

.skills img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin: 0.4rem;
}

.skillsSetContainer {
  max-width: 35rem;
}
.skillsSetContainer.miscSection {
  max-width: 50rem;
}

@media screen and (max-width: 1119px) {
  .skillsSetContainer.miscSection {
    max-width: 35rem;
  }
}

@media screen and (max-width: 940px) {
  .skills {
    padding: 0.7rem;
  }

  .skills .skill {
    margin: 0 0.5rem 1.5rem;
    width: 5.5rem;
    font-size: 10px;
    padding: 0.3rem 0.1rem;
  }

  .skills h2 {
    width: 70%;
    margin: 1rem auto 0;
    font-size: 1.3rem;
  }

  .skills img {
    width: 50px;
    height: 50px;
  }

  .skills hr {
    width: 10%;
  }
}
