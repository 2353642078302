.header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  backdrop-filter: blur(3px);
  animation: scrollDown 2s ease-in-out;
}

@keyframes scrollDown {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0px);
  }
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 10px;
}

.container {
  max-width: 1150px;
}

.navbar-brand {
  font-weight: 500;
}

.navbar-brand:hover {
  cursor: pointer;
}

.navbar-brand span {
  color: var(--color-primary-light);
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: 700;
}

.linkContainer {
  font-size: 1.1rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  transition: all 0.4s ease;
  text-transform: capitalize;
}

.linkContainer:hover,
.linkContainer:active {
  background-color: var(--color-primary);
}

.linkContainer:hover a.link {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.resume {
  background: linear-gradient(0deg, rgb(8, 87, 185) 0%, rgb(65, 149, 252) 100%);
  border-radius: 10px;
}

.resume:hover {
  background: linear-gradient(0deg, rgb(3, 70, 152) 0%, rgb(60, 147, 254) 100%);
}

a.link {
  color: #ccc;
}

.resume a.link {
  color: #fff;
}

.socials {
  margin-left: 0.7rem;
  padding-left: 0.2rem;
  border-left: 1.5px solid rgba(255, 255, 255, 0.3);
}

.socials a {
  color: #fff;
  font-size: 1.5rem;
  margin: auto 0.5rem;
}

.socials i {
  transition: 0.2s ease-in-out;
}

.socials i:hover {
  transform: scale(1.15);
}

.fa-linkedin:hover,
.fa-github:hover,
.fa-envelope:hover {
  color: grey;
}

/* The envelope icon in "Contact Me" link should stay the same color */
.linkContainer a .fa-envelope:hover {
  color: #fff;
}

@media screen and (min-width: 941px) and (max-width: 1200px) {
  .linkContainer a.link {
    font-size: 0.9rem;
  }
  .container {
    max-width: 100%;
    padding: 0;
  }
  .navbar-brand {
    font-size: 1rem;
    margin-left: 0;
  }
}

@media screen and (max-width: 940px) {
  .header {
    border-bottom: 3px solid var(--color-primary-light);
  }

  button.navbar-toggler {
    outline: none;
    border: none;
    padding: 0;
    margin-right: 10px;
  }

  button.navbar-toggler:focus {
    outline: none;
  }

  .linkContainer a.link {
    font-size: 0.85rem;
  }

  .linkContainer.resume {
    margin: 6px 0;
  }

  .linkContainer {
    width: 40%;
    display: flex;
    align-items: center;
  }

  .header .link {
    font-size: 0.7rem;
    margin: 0 !important;
  }

  .nav-link:hover {
    background-color: transparent;
  }

  .resume {
    border-radius: 10px;
    width: 35%;
  }

  .socials {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    border: none;
  }

  .socials a {
    font-size: 1.5rem;
    margin: auto 0.7rem;
  }
}
