.timeline-container {
  padding: 6rem 0;
  overflow: hidden; /* prevent issues with the navbar on mobile */
}

.timeline-container h4 {
  font-size: 18px;
}

.timeline-container h3 {
  font-size: 22px;
}

.timelineText {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.blueBorder .vertical-timeline-element-content {
  border-top: 5px solid rgb(33, 150, 243);
  border-radius: 0 0 12px 12px;
}
.redBorder .vertical-timeline-element-content {
  border-top: 5px solid rgb(233, 30, 99);
  border-radius: 0 0 12px 12px;
}
.current .vertical-timeline-element-content {
  border-top: 5px solid white;
}

.timelineCurrentHeader {
  color: white;
}

.timelineCurrentText {
  color: white;
}

.timelineHeader {
  color: #666;
}

.timelineIcon {
  padding: 12px;
}

.timeLineLinkIcon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 1rem;
  right: 1.5rem;

  &:hover {
    color: var(--color-offWhite);
  }
}

.toolsContainer {
  margin: 1rem 0 1.5rem;
}

.tool.current {
  background-color: white;
  color: var(--color-primary-light);
}
.tool.current:hover {
  background-color: #f5f5f5;
}

.imageContainer {
  overflow: hidden;
}

.timeLineImage {
  width: 100%;
  box-shadow: rgb(167 167 167 / 50%) 0px 4px 6px;
}

.timeLineImage {
  transition: 0.3s ease-in-out;
}

.timeLineImage:hover {
  transform: scale(1.05);
}

.fa-link {
  color: #d1d1d1;
  transition: 0.15s ease-in-out;
}

.fa-link.current {
  color: #f2f2f2;
}

.fa-link:hover {
  color: #b5b5b5;
}

@media screen and (min-width: 500px) and (max-width: 900px) {
  .vertical-timeline-element-content {
    max-width: 400px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1169px) {
  .vertical-timeline-element-content {
    max-width: 600px;
  }
}

@media screen and (max-width: 940px) {
  .timeline-container {
    padding: 2rem 0 4rem;
  }

  .current .vertical-timeline-element-date {
    color: white;
  }

  .toolsContainer {
    text-align: left;
  }
}
