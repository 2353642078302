.link {
  width: max-content;
  display: block;
  margin: 0 auto;
}

.backToTop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto 2.5rem;
  padding: 0.4rem;
  width: 10rem;
  background-color: transparent;
  border: 2px solid var(--color-primary);
  border-radius: 30px;
  gap: 0.5rem;
  font-size: 0.85rem;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}

.backToTop:hover,
.backToTop:hover a.link,
.footer .contactInfo,
.footer a,
.footer i,
.footer .copyright {
  color: var(--color-offWhite);
}

.backToTop:hover {
  border: 2px solid var(--color-offWhite);
  background-color: var(--color-primary);
}

.backToTop:active {
  background-color: var(--color-primary-light);
}
.backToTop:focus {
  outline: none;
}

.link:hover {
  text-decoration: none !important;
}

.backToTop {
  color: var(--color-primary);
}

.footer {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  background: linear-gradient(
    180deg,
    var(--color-primary) 0%,
    var(--color-primary-light) 100%
  );
  gap: 1rem;
}

.footer .contactInfo {
  font-weight: 600;
  margin-top: 1rem;
}

.footer .phone {
  margin: 0.5rem 0 0;
}

.footer .email {
  margin: 0 0 1.5rem;
}

.footer p {
  margin: 0.2rem 0 0;
}

.footer i {
  margin: 0 1rem;
  font-size: 2rem;
  transition: all 0.1s ease-in-out;
}

.footer i:hover {
  transform: scale(1.15);
}

.footer .copyright {
  font-size: 10px;
  margin-top: 1rem;
}
